import React from "react"

import SEO from "../../components/SEO"
// import { Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"


import home_header_bk from "../../../static/images/2/home/header_bk.svg"

import AOS from 'aos';


import seoImage from '../../../static/images/2/webinar/dec-2020/facebook.jpg';

const Dec = () => {

    React.useEffect(() => {
        AOS.init();
    },[]);
    
    return (
      <>
        <SEO
          title="brillea - helping purpose-driven startups launch & grow"
          description="Want more customers using Facebook & Instagram ads. Join our Webinar & learn how to run your own  campaigns"
          banner={seoImage}
        />

        <div className={"section section_header section_home"}>
          <div className="section_header__bk_image">
            <img src={home_header_bk} alt="brillea smarter digital marketing" />
          </div>
          <Container>
            <Row>
              <Col
                className={"homeHeaderContent text-center"}
              >
                <h1 className="mb-3">Want more customers using Facebook &amp; Instagram ads?</h1>
                <h2 className="color_purple_light">Preview the Webinar recording &amp; learn how to run your own  campaigns</h2>
              </Col>
              
            </Row>
            <Row className="text-center mb-5">
            <div className="embed-responsive embed-responsive-16by9">
            <iframe className="embed-responsive-item" src="https://player.vimeo.com/video/491148242" title="webinar video" width="900" height="460" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
            </div>
            
            </Row>
          </Container>

         
        </div>

      </>
    )
}
export default Dec
